import {flare} from "@flareapp/flare-client";

const app = new Vue({
    el: '#app',
    data() {
        return {
            route,
        }
    },
    mounted() {
        this.initHeroSlider();
    },
    methods: {
        initHeroSlider() {
            new Glider(document.querySelector('.glider'), {
                slidesToShow: 6,
                slidesToScroll: 6,
                draggable: true,
                dots: false,
                exactWidth: 64,
                arrows: {
                    prev: '.glider-prev',
                    next: '.glider-next'
                },
                responsive: [
                    {
                        breakpoint: 775,
                        settings: {
                            exactWidth: 286,
                        }
                    }
                ]
            });
        },
    }
});
